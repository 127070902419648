import FqApi from '@/services/fq-api'
import mimeTypes from 'mime-types'
import Base64ArrayBuffer from '@/components/utils/Base64ArrayBuffer'

export default {
  convertToBase64(contentType, reportBytes) {
    // const pdf = Buffer.from(reportBytes, 'binary').toString('base64')
    const pdf = Base64ArrayBuffer.encode(reportBytes)
    return `data:${contentType.toLowerCase()};base64,${pdf}`
  },
  async getReportData(reportName, format, query) {
    const url = `/reports/getreport/${reportName}/${format}?query=${query}`
    const result = await FqApi.get(url, { responseType: 'arraybuffer' })
    // console.log(result)
    return result
  },
  async getReportDataEx(reportName, format, cacheKey) {
    const url = `/reports/getreportex/${reportName}/${format}?cacheKey=${cacheKey}`
    const result = await FqApi.get(url, { responseType: 'arraybuffer' })
    // console.log(result)
    return result
  },
  async postReportData(reportName, format, keyValuePairs) {
    const url = `/reports/postreport/${reportName}/${format}`
    const result = await FqApi.post(url, keyValuePairs, { responseType: 'arraybuffer' })
    // console.log(result)
    return result
  },
  async getReportBlob(reportName, format, query) {
    const url = `/reports/getreport/${reportName}/${format}?query=${query}`
    const result = await FqApi.get(url, { responseType: 'arraybuffer' })
    const pdfBlob = new Blob([result.data], { type: 'application/pdf' })
    return pdfBlob
  },
  async getReportObject(reportName, format, query) {
    const url = `/reports/getreportobject/${reportName}/${format}?query=${query}`
    const result = await FqApi.get(url)
    result.data.reportData = `data:application/pdf;base64,${result.data.reportData}`
    // return result.data
    return result.data
  },
  async getReport(reportName, format, query) {
    const url = `/reports/getreport/${reportName}/${format}?query=${query}`
    const result = await FqApi.get(url, { responseType: 'arraybuffer' })
    const pdf = Buffer.from(result.data, 'binary').toString('base64')
    return `data:${result.headers['content-type'].toLowerCase()};base64,${pdf}`
  },
  async getReportBase64(reportName, format, query) {
    const url = `/reports/getreport/${reportName}/${format}?query=${query}`
    const result = await FqApi.get(url, { responseType: 'arraybuffer' })
    const pdf = Buffer.from(result.data, 'binary').toString('base64')
    return pdf
  },
  async exportReport(reportName, format, query) {
    const url = `/reports/getreport/${reportName}/${format}?query=${query}`
    const result = await FqApi.get(url, { responseType: 'arraybuffer' })
    // var blob = this.createBlobFromString(result.data, 'application/vnd.ms-excel')
    let blob = new Blob([result.data], { type: result.headers['content-type'].toLowerCase() })
    const reportUrl = window.URL.createObjectURL(blob)
    const dummyLink = document.createElement('a')
    dummyLink.href = reportUrl
    dummyLink.download = `report.${mimeTypes.extension(result.headers['content-type'].toLowerCase())}`
    document.body.appendChild(dummyLink)
    dummyLink.click()
  },
  async exportReportEx(reportName, format, keyValuePairs) {
    const url = `/reports/postreport/${reportName}/${format}`
    const result = await FqApi.post(url, keyValuePairs, { responseType: 'arraybuffer' })
    let blob = new Blob([result.data], { type: result.headers['content-type'].toLowerCase() })
    const reportUrl = window.URL.createObjectURL(blob)
    const dummyLink = document.createElement('a')
    dummyLink.href = reportUrl
    dummyLink.download = `report.${mimeTypes.extension(result.headers['content-type'].toLowerCase())}`
    document.body.appendChild(dummyLink)
    dummyLink.click()
  },
  async getReportUrl(reportName, format, query) {
    const url = `/reports/getreport/${reportName}/${format}?query=${query}`
    const result = await FqApi.get(url, { responseType: 'arraybuffer' })
    // var blob = this.createBlobFromString(result.data, 'application/vnd.ms-excel')
    let blob = new Blob([result.data], { type: result.headers['content-type'].toLowerCase() })
    const reportUrl = window.URL.createObjectURL(blob)
    // console.log(reportUrl)
    return reportUrl
  },
  async getImage(id) {
    const url = `/quotes/images/data/${id}`
    const result = await FqApi.get(url, { responseType: 'arraybuffer' })
    // console.log(result)

    // const image = btoa(String.fromCharCode(...new Uint8Array(result.data)))
    const image = Buffer.from(result.data, 'binary').toString('base64')
    return `data:${result.headers['content-type'].toLowerCase()};base64,${image}`
  },
  /**
   *
   * @param {*} id Guid
   */
  async getImageDetail(id) {
    const url = `/quotes/images/${id}`
    const result = await FqApi.get(url)
    // console.log(result)

    // const image = btoa(String.fromCharCode(...new Uint8Array(result.data)))
    // console.log(result)
    return result.data
  },
  /**
   *
   * @param {Array} ids Array of Guid
   */
  async getImageSummaries(ids) {
    const imageIds = ids.join()
    // console.log(imageIds)
    const url = `/quotes/images/summaries?imageIds=${imageIds}`
    // const url = '/quotes/imagesummaries/f13be72e-736e-456f-99e4-4eac6fbd84e8,f3df6769-53ce-4c4d-a5e9-443c1c4f2f9f,ac9b72a4-353b-4e5a-a08d-9aa2e912e9ae,d5165ff5-a765-4f5a-a419-3ece1f20bd6d,46e0cfcd-3060-49ee-8327-c071adfa39ab,ca2e9386-68d8-4077-8158-2084154ae93e,7fc05818-4d4b-49db-ada2-840cc040ce7a,eaa8cae5-e888-4f29-9b53-f60ae890d51d'
    const result = await FqApi.get(url)
    return result.data
  },
  postRemark(remark) {
    return FqApi.post('/remarks', remark)
  },
  async cacheParameter(parameter, value) {
    const url = `/reports/cacheparameter/${parameter}`
    // console.log(url, value)
    const result = await FqApi.post(url, { key: parameter, value: value })
    return result
  },
  /**
   *
   * @param {Array} parameters Array of { key, value }
   */
  async cacheParameters(parameters) {
    const url = '/reports/cacheparameters'
    const result = await FqApi.post(url, parameters)
    return result.data
  },
  async getQuote(purchaseOrderId) {
    let url = `/purchaseorders/getquote/${purchaseOrderId}`
    const result = await FqApi.get(url)
    return result.data
  }
}

<template>
  <div :active.sync="isActive">
    <div class="field">
      <span>Select report</span>
    </div>
    <div class="field">
      <div class="pretty p-default p-round p-bigger p-smooth">
        <input type="radio"
          id="hoursDollarQuote"
          value="H"
          v-model="quotingMethod">
        <div class="state p-primary-o">
          <label>Hours Dollar Quote</label>
        </div>
      </div>
      <div class="pretty p-default p-round p-bigger p-smooth">
        <input type="radio"
          id="hoursDollarQuote"
          value="D"
          v-model="quotingMethod">
        <div class="state p-primary-o">
          <label>Dollar Only Quote</label>
        </div>
      </div>
      <!-- <input class="is-checkradio"
        id="hoursDollarQuote"
        type="radio"
        name="hoursDollarQuote"
        value="H"
        v-model="quotingMethod">
      <label for="hoursDollarQuote">Hours Dollar Quote</label>
      <input class="is-checkradio"
        id="dollarOnlyQuote"
        type="radio"
        name="dollarOnlyQuote"
        value="D"
        v-model="quotingMethod">
      <label for="dollarOnlyQuote">Dollar Only Quote</label> -->
    </div>
    <div class="is-divider" />
    <div class="field">
      <div class="pretty p-icon p-curve p-smooth p-bigger">
        <input id="individualItemValue"
          type="checkbox"
          v-model="individualItemValue">
        <div class="state p-primary">
          <i class="icon mdi mdi-check" />
          <label>Individual Item {{ quotingMethod === 'H' ? 'Hours' : '$' }}</label>
        </div>
      </div>
      <!-- <input class="is-checkradio"
        id="individualItemValue"
        type="checkbox"
        checked="checked"
        name="individualItemValue"
        v-model="individualItemValue">
      <label for="individualItemValue">Individual Item {{ quotingMethod === 'H' ? 'Hours' : '$' }}</label> -->
    </div>
    <div class="field">
      <div class="pretty p-icon p-curve p-smooth p-bigger">
        <input id="partsListPrice"
          type="checkbox"
          v-model="partsListPrice">
        <div class="state p-primary">
          <i class="icon mdi mdi-check" />
          <label>Parts List Price</label>
        </div>
      </div>
      <!-- <input class="is-checkradio"
        id="partsListPrice"
        type="checkbox"
        checked="checked"
        name="partsListPrice"
        v-model="partsListPrice">
      <label for="partsListPrice">Parts List Price</label> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuoteExportText',
  props: {
    value: null,
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: this.active || false,
      quotingMethod: this.value.quotingMethod,
      individualItemValue: true,
      partsListPrice: true,
      reportOptions: ''
    }
  },
  created() {
    this.quotingMethod = this.value.quotingMethod
  },
  mounted() {},
  methods: {
    generateReportOptions() {
      if (this.quotingMethod == 'H') {
        this.reportOptions = '1'
        if (this.individualItemValue) {
          this.reportOptions += '1'
        } else {
          this.reportOptions += '0'
        }
        if (this.partsListPrice) {
          this.reportOptions += '1'
        } else {
          this.reportOptions += '0'
        }

        this.reportOptions += '0'
        this.reportOptions += '0'
        this.reportOptions += '0'
      } else {
        this.reportOptions += '0'
        this.reportOptions += '0'
        this.reportOptions += '0'
        this.reportOptions += '1'
        if (this.individualItemValue) {
          this.reportOptions += '1'
        } else {
          this.reportOptions += '0'
        }
        if (this.partsListPrice) {
          this.reportOptions += '1'
        } else {
          this.reportOptions += '0'
        }
      }
      this.$emit('generate-text-email', this.reportOptions)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
</style>

import { mapGetters, mapActions } from 'vuex'

const moduleName = 'printpreview'

export default {
  computed: {
    ...mapGetters(moduleName, {
      returnRoute: 'returnRoute',
      getEmailer: 'getEmailer',
      reportParameters: 'reportParameters'
    }),
    ...mapGetters(['licenceExpired'])
  },
  methods: {
    ...mapActions(moduleName, { addEmailer: 'addEmailer', removeEmailer: 'removeEmailer' }),
    ...mapActions(moduleName, { addParameters: 'addParameters', removeParameters: 'removeParameters' })
  }
}
